<!--
The agent form for updating agents. Basically edits the dbo.Agent table, updates Opt info and pulls data from the onboarding portal.
//-->
<template>
    <div id="editformtop">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <p v-if="baseAgent.OptID != null && (baseAgent.Status == 'Terminated' || baseAgent.Status == 'Inactive')">Note: If you need to change the status of this agent, you will need to do so in Opt! first!<br>It will take 24 hrs for the changes to be reflected in HQ.</p>
        <v-form v-if="!loading" lazy-validation v-model="valid2" ref="form2" :disabled="saving">
            <v-row wrap dense>
                <!--begin-->
                <v-col cols="12" class="px-5">
                    <v-row class="d-flex">
                        <h4 class="font-weight-regular" style="text-align:left; display:inline-block; background:#fff; padding:5px 20px 5px 0; float:left;">Agent Information</h4>
                        <v-divider class="mt-5 pb-4"></v-divider>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3" class="pl-5">
                            <v-text-field label="First Name*" v-model="baseAgent.FirstName" :rules="[rules.required,rules.not_upper_case,rules.no_special_characters]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="pl-5">
                            <v-text-field label="Middle Name" v-model="baseAgent.MiddleName" :rules="[rules.no_special_characters]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="pl-5">
                            <v-text-field label="Last Name*" v-model="baseAgent.LastName" :rules="[rules.required,rules.not_upper_case,rules.no_special_characters]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="pl-5">
                            <v-select label="Suffix" v-model="baseAgent.Suffix" :items="['', 'Sr', 'Jr', 'II', 'III', 'IV']"></v-select>
                        </v-col>
                        <v-col cols="12" md="4" class="pl-5 d-flex align-center">
                            <div class="flex-grow-1">
                                <v-text-field label="Preferred Name" v-model="baseAgent.PreferredName" :rules="[rules.not_upper_case,rules.valid_name_characters]"></v-text-field>
                            </div>
                            <div style="width:40px;">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="#999999" dark v-bind="attrs" v-on="on">
                                            fas fa-question-circle
                                        </v-icon>
                                    </template>
                                    <span>
                                        <p style="max-width:200px">Fill out Preferred Name with a full name or complete agency name. This field will primarily be displayed on leaderboards and be used as entered, not combined with a last name or nick name. </p>
                                    </span>
                                </v-tooltip>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" class="pl-5 d-flex align-center">
                            <div class="flex-grow-1">
                                <v-text-field label="Nick Name" v-model="baseAgent.NickName" :rules="[rules.not_upper_case,rules.no_special_characters]"></v-text-field>
                            </div>
                            <div style="width:40px;">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="#999999" dark v-bind="attrs" v-on="on">
                                            fas fa-question-circle
                                        </v-icon>
                                    </template>
                                    <span>
                                        <p style="max-width:200px">The agent Nick Name will replace their first name in the Agent Directory. For example if an agent goes by a different first name, or uses their middle name as their first name, then enter that name here.</p>
                                    </span>
                                </v-tooltip>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" class="pl-5 d-flex align-center">
                            <v-checkbox label="Hide Middle Name" v-model="baseAgent.HideMiddlename"></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-select label="Gender" :value="baseAgent.Gender" :items="genderList" clearable @input="baseAgent.Gender = $event ?? null" />
                        </v-col>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-select label="MaritalStatus" :value="baseAgent.MaritalStatus" :items="marriageList" clearable @input="baseAgent.MaritalStatus = $event ?? null"  />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-select label="Status*" :disabled="baseAgent.OptID != null && (baseAgent.Status == 'Terminated' || baseAgent.Status == 'Inactive')" :items="status_levels" item-value="id" item-text="Status" v-model="baseAgent.Status" :rules="[rules.required]" prepend-icon="fas fa-thumbs-up"></v-select>
                        </v-col>
                        <v-col cols="12" md="6" class="pl-5">
                            <q-date-picker v-model="baseAgent.ContractStartDt" label="Contract Start Date"></q-date-picker>
                        </v-col>
                    </v-row>
                    <v-row>

                        <v-col cols="12" md="6" class="pl-5" v-if="baseAgent.PCSAgentInd">
                            <q-date-picker v-model="baseAgent.PCSAgentStartDate" :allow_time="false" label="PCS Start Date"></q-date-picker>
                            <q-date-picker v-model="baseAgent.PCSAgentEndDate" :allow_time="false" label="PCS End Date"></q-date-picker>
                        </v-col>
                    </v-row>
                    <v-row v-if="['Terminated','Rejected'].includes(baseAgent.Status)">
                        <v-col cols="12" md="6" class="pl-5">
                            <v-select label="Reason" :items="termination_reasons" item-value="id" item-text="Reason" v-model="baseAgent.TerminationReason" :rules="[rules.required]" prepend-icon="fas fa-ban"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6" class="pl-5">
                            <q-date-picker v-model="baseAgent.BirthDt" label="Birth Date"></q-date-picker>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-select label="Slingshot Badge" v-model="baseAgent.SlingshotBadges" :items="slingshotBadgeItems"></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex pt-8">
                        <h4 class="font-weight-regular" style="text-align:left; display:inline-block; background:#fff; padding:5px 20px 5px 0; float:left;">Contact</h4>
                        <v-divider class="mt-5 pb-4"></v-divider>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-text-field label="Email*" v-model="baseAgent.Email" :rules="[rules.required, rules.email]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-text-field label="Business Email" v-model="baseAgent.BusinessEmail" :rules="[rules.nullable_email]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-text-field label="Phone*" v-model="baseAgent.Phone" :rules="[rules.required, rules.phone]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-text-field label="Business Phone" v-model="baseAgent.BusinessPhone" :rules="[rules.phone]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-text-field label="Cell Phone" v-model="baseAgent.CellPhone" :rules="[rules.phone]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-text-field label="Summit Phone" v-model="baseAgent.SummitPhone" :rules="[rules.phone]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex pt-8">
                        <h4 class="font-weight-regular" style="text-align:left; display:inline-block; background:#fff; padding:5px 20px 5px 0; float:left;">Address</h4>
                        <v-divider class="mt-5 pb-4"></v-divider>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pl-5">
                            <v-text-field label="Street Address" v-model="baseAgent.Street1" :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-text-field label="City" v-model="baseAgent.City" :rules="[rules.required]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pl-5">
                            <v-text-field label="County" v-model="baseAgent.County" :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4" class="pl-5">
                            <v-select label="State*" :items="states" v-model="baseAgent.State" :rules="[rules.required]"></v-select>
                        </v-col>
                        <v-col cols="12" md="4" class="pl-5">
                            <v-text-field label="Zip*" v-model="baseAgent.Zip" :rules="[rules.required, rules.zip]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex pt-8">
                        <v-col>
                            <v-checkbox label="Fully Licensed?" v-model="baseAgent.IsFullyLicensed"></v-checkbox>
                            <div v-if="!baseAgent.IsFullyLicensed">Agents who are not fully licensed will not have an NPN number, <em><strong>they will not get an OptID/Login</strong></em> and have limited access to HQ. <strong>Be sure to update the agent when they are fully licensed!</strong></div>
                        </v-col>
                    </v-row>

                    <v-row class="d-flex">
                        <h4 class="font-weight-regular" style="text-align:left; display:inline-block; background:#fff; padding:5px 20px 5px 0; float:left;">
                            Statuses & Options
                        </h4>
                        <v-divider class="mt-5 pb-2"></v-divider>
                    </v-row>
                    <v-row class="d-flex">
                        <v-col cols="6">
                            <v-checkbox class="mt-1" style="height: 30px" label="Advisory Board Member?" v-model="baseAgent.IsAdvisoryBoardMember"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox class="mt-1" style="height: 30px" label="PCS Agent" v-model="baseAgent.PCSAgentInd"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox class="mt-1" style="height: 30px" label="Seasoned New Agent" v-model="baseAgent.SeasonedNewAgentInd"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox  class="mt-1" style="height: 30px" label="Owner's Circle Member" v-model="baseAgent.IsOwnerCircleMember"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox  class="mt-1" style="height: 30px" label="Sherpa" v-model="baseAgent.HasSherpaInd"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox  class="mt-1" style="height: 30px" label="Hide From Leaderboards" v-model="baseAgent.HideFromLeaderboardsInd"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox  class="mt-1" style="height: 30px" label="PCS On Hold" v-model="baseAgent.PCSOnHoldInd"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox  class="mt-1" style="height: 30px" label="DFL Certified" v-model="baseAgent.DFLCertifiedInd"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox  class="mt-1" style="height: 30px" label="IUL Certified" v-model="baseAgent.IULCertifiedInd"></v-checkbox>
                        </v-col>
						<v-col cols="6">
                            <v-checkbox  class="mt-1" style="height: 30px" label="IBC Certified" v-model="baseAgent.IBCCertifiedInd"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox  class="mt-1" style="height: 30px" label="Annuity Certified" v-model="baseAgent.AnnuityCertifiedInd"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox  class="mt-1" style="height: 30px" label="PCS On Probation" v-model="baseAgent.PCSProbationInd"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox  class="mt-1" style="height: 30px" label="Annuity SME" v-model="baseAgent.AnnuitySME"></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row class="d-flex pt-5">
                        <h4 class="font-weight-regular" style="text-align:left; display:inline-block; background:#fff; padding:5px 20px 5px 0; float:left;">
                            Legacy Advisory Board
                        </h4>
                        <v-divider class="mt-5 pb-2"></v-divider>
                    </v-row>
                        <v-col cols="6" class="">
                            <v-select label="Select" v-model="baseAgent.AdvisoryBoardMemberDetail" :items="AdvisoryBoardMemberDetailItems"></v-select>
                        </v-col>

                    <v-row class="d-flex" v-if="baseAgent.IsFullyLicensed">
                        <h4 class="font-weight-regular" style="text-align:left; display:inline-block; background:#fff; padding:5px 20px 5px 0; float:left;">NPN Numbers</h4>
                        <v-divider class="mt-5 pb-4"></v-divider>
                    </v-row>
                    <v-row v-if="baseAgent.IsFullyLicensed">
                        <v-col cols="12" md="6" class="px-5">
                            <v-text-field :disabled="disableNPN" label="NPN #" v-model="baseAgent.NPN"></v-text-field>
                            <v-text-field v-if="baseAgent.IsFullyLicensed && baseAgent.OptID == null" label="SSN #" v-model="baseAgent.SSN" :rules="[rules.required]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="px-5" v-if="baseAgent.IsFullyLicensed && baseAgent.OptID == null">
                            <v-select validate-on-blur label="Opt! Default Lead Type*" :items="lead_options" v-model="baseAgent.LeadTypeDefault" :rules="[rules.required]"></v-select>
                            <v-select validate-on-blur label="Opt! Lead Types*" multiple chips :items="lead_options" v-model="baseAgent.LeadTypesAvailable" :rules="[rules.required]"></v-select>
                        </v-col>
                    </v-row>
                    <!-- newly added doing business as fields placeholders -->
                    <v-row v-if="baseAgent.IsFullyLicensed">
                        <v-col cols="12" md="6" class="px-5">
                            <v-text-field label="DBA Name" v-model="baseAgent.DoingBusinessAsName"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="px-5">
                            <v-text-field :disabled="disableBusinessNPN" label="DBA NPN #" v-model="baseAgent.DoingBusinessAsNPN"></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- new fields end here -->
                    <v-row class="d-flex pt-8">
                        <h4 class="font-weight-regular" style="text-align:left; display:inline-block; background:#fff; padding:5px 20px 5px 0; float:left;">Direct Upline</h4>
                        <v-divider class="mt-5 pb-4"></v-divider>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <q-agent-lookup :filters="{'Status' : 'Active,Available,Lapsed,Stalled,Terminated'}" label="Upline Agent" v-model="baseAgent.UplineAgentCode" :rules="[rules.required]" placeholder="Type to Search for Agent"></q-agent-lookup>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Lead Agent ID" v-model="baseAgent.LeadAgentID" :rules="[rules.number]"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Agency Integrator ID" v-model="baseAgent.AgencyIntegratorID" :rules="[rules.number]"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Ext UID" v-model="baseAgent.ExtUID"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-divider class="mt-4 pb-4"></v-divider>
                        <p class="caption float-right" style="background-color:#fff; padding:5px 0px 5px 20px;">Clicking Save will update the user in Opt! and HQ.</p>
                    </v-row>
                    <v-row>
                        <v-progress-linear right size="20" v-if="saving" indeterminate></v-progress-linear>
                    </v-row>
                    <v-row>
                        <v-alert type="error" v-if="error">
                            {{ error }}
                        </v-alert>
                        <v-alert type="warning" v-if="warning">
                            {{ warning }}
                        </v-alert>
                        <v-progress-linear right size="20" v-if="saving" indeterminate></v-progress-linear>
                        <v-btn @click="resetForm" text class="mt-4" small>Reset all fields</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" depressed @click="saveAgent(true)" :disabled="saving">
                            Save
                            <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
                <!--end-->
                <!--<v-col cols="12" sm="6">
                    <v-text-field label="SSN #" v-model="baseAgent.SSN"></v-text-field>
                </v-col>//-->
            </v-row>
        </v-form>
    </div>
</template>
<script>
import QDatePicker from '@/components/utils/QDatePicker.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Level from '@/store/Models/AgentManagement/Level'
import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import StateOptions from '@/store/StateOptions'
import StateAbbreviations from '@/store/StateAbbreviations'
import rules from '@/components/utils/ValidationRules'
import Agent from '@/store/Models/Agent'
import OptLeadOptions from '@/store/OptLeadOptions'

export default {
    props: ['agentCode'],
    data() {
        return {
            baseAgent: {}, // holds the base agent data for saving to dbo.Agent
            saving: false, //for progress bars
            warning: null,
            error: null,
            valid2: true, // whether the form is valid or not.
            states: StateOptions, //for the state drop down list
            state_abbreviations: StateAbbreviations,
            rules: rules, //validation rules imported.
            agent_loading: false, //progress indicator
            disableNPN: false,
            disableBusinessNPN: false,
            AdvisoryBoardMemberDetail: '',
            AdvisoryBoardMemberDetailItems: ['', 'normal', 'legacy']
        }
    },
    mounted: function() {
        //gets the levels data to populate the dropdowns.
        this.getLevels();
        //this.reset();
    },
    computed: {
        //filters levels for the appropriate drop downs, Leadership or Contracting.
        'contract_levels': function() {
            return Level.query().where("LevelType", 'Contract').get()
        },
        'leadership_levels': function() {
            return Level.query().where("LevelType", 'Leadership').get()
        },
        'lead_options': function() {
            return OptLeadOptions
        },
        //Available status levels for the dropdown.
        'status_levels': function() {
            //dont allow changing the Status when the status is inactive or terminated.
            return [
                'Active',
                'Available',
                'Lapsed',
                'Rejected',
                'Stalled',
                'Terminated'
            ]
        },
        // currently populated with
        // placeholder reasons
        'termination_reasons': function() {
            return [
                'Resigned',
                'Retired',
                'Terminated by HR',
                'Terminated by Agency Owner',
                'Terminated by Legal',
                'Deceased',
                'Other',
                'Background',
                'Duplicate',
                'Agent decided not to move forward',
                'AO decided not to move forward'
            ]
        },
        //we don't show the form until all the data for the dropdowns are populated.
        'loading': function() {
            return this.$store.state.entities.level.loading || this.agent_loading;
        },
        'loaded': function() {
            return this.$store.state.entities.level.loaded;
        },
        'slingshotBadgeItems': function() {
            return [
                {'text': 'None', 'value': ''},
                {'text': 'Regular', 'value': 'regular'},
                {'text': 'Golden', 'value': 'golden'}
            ]

        },
        genderList() {
            return this.user.enums.gender
        },
        marriageList() {
            return this.user.enums.marriage
        }
    },
    methods: {
        //gets the Agent Levels entries for populating the drop downs - use compute functions to segment the various types.
        getLevels() {
            var g = this;
            QuilityAPI.getAgentManagement_Levels().then(function() {
                g.reset();
            })
        },
        //gets the base agent if we have a AgentCode passed to us via the props
        getBaseAgent() {
            this.agent_loading = true
            var g = this;
            QuilityAPI.getBaseAgent(this.agentCode).then(function(json) {
                g.$set(g, 'baseAgent', json.data)
                if (typeof json.data.NPN != 'undefined' && json.data.NPN != null && json.data.NPN != '') {
                    g.disableNPN = true
                } else {
                    g.disableNPN = false
                }
                if (typeof json.data.DoingBusinessAsNPN != 'undefined' && json.data.DoingBusinessAsNPN != null && json.data.DoingBusinessAsNPN != '') {
                    g.disableBusinessNPN = true
                } else {
                    g.disableBusinessNPN = false
                }
                g.agent_loading = false
            })
        },
        //slides the form to the top
        goToTop: function() {
            this.$vuetify.goTo('#editformtop', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
        //if we need to translate a Level Name to an ID, use this passing the level name.
        getLevelID: function(level) {
            return Level.query().where("LevelName", level).first()
        },
        //saves the agent... also updates the Opt account
        saveAgent() {
            var g = this;
            //check the form to see if it is validated.
            if (!this.$refs['form2'].validate()) {
                this.goToTop();
                this.warning = "Please correct invalid fields."
                setTimeout(function() { this.warning = null }, 6000)
                this.saving = false;
                return false;
            }
            this.saving = true;
            delete this.baseAgent.LastChangeBy
            QuilityAPI.updateBaseAgent(this.baseAgent).then((resp) => {
                if (resp.error) {
                    this.error = resp.message ? resp.message : resp.msg + " " + resp.errors;
                    this.showError(this.error);
                    this.goToTop();
                    setTimeout(function() { this.error = null }, 12000)
                } else {
                    Agent.commit((state) => {
                        state.currentAgent.NeedsUpdate = true
                    })
                    this.$set(this, 'baseAgent', resp.data)
                    this.$emit('saved', resp.data)
                }
                this.saving = false;
            })
            .catch((err) => {
                g.error = err.message ? err.message : err.msg + " " + err.errors;
                this.error = g.error
                this.showError(g.error);
                setTimeout(function() { this.error = null }, 6000)
                this.saving = false;
                this.goToTop();
            });
        },
        //reset the entire form... not really needed when editing?
        reset() {
            this.warning = null
            this.saving = false
            this.getBaseAgent();
            this.step = 1;
            this.onboardingAgent = null;
            this.onboardingAgent_entry_id = null;
            this.lookupEmail = null
        },
        resetForm: function() {
            this.reset();
        },
        getStateAbbreviation(state) {
            if (this.states.indexOf(state) > -1) {
                return state
            }
            if (typeof this.state_abbreviations[state] != 'undefined') {
                return this.state_abbreviations[state];
            }
            return null
        },
    },
    watch: {
        'agentCode': function(newV) {
            this.reset();
        },
        'baseAgent.NPN': function(newV) {
            const msg = "Full functionality of HQ will not be available without a valid NPN number."
            if (!newV) {
                if (this.warning) {
                    this.warning += `<br>${msg}`
                } else {
                    this.warning = msg
                }
            } else if (newV && this.warning == msg) {
                // get rid of this warning
                // since a NPN has been enterd
                this.warning = null
            }
        },
    },
    components: {
        QAgentLookup,
        QDatePicker,
    }
}
</script>
