<template>
	<div class="bonus-item__row" :class="isMobileTableView && 'flex-wrap-reverse'">
		<div class="bonus-item__col-left">
			<BirdDogBonusReportTable
				v-model="search"
				:bonus-type="bonusType"
				:items="items"
				:total-bonus="totalBonus"
				:filters="computedFilters"
				:options="options"
				:server-items-length="serverItemsLength"
				:loading="itemsLoadingStatus.loading"
				:error="itemsLoadingStatus.error"
				@search="searchData"
				@update-options="options = $event"
				@load-data="loadData"
				@export-results="exportResults"
			>
				<template #item.AgentName="{ item, value }">
					<router-link v-if="item.AgentCode" :to="`/agents/${item.AgentCode}`" @click.native.stop class="bonus-item__table-link-btn">{{ value || 'go to agent details' }}</router-link>
					<span v-else>{{ value || 'NA' }}</span>
				</template>
			</BirdDogBonusReportTable>
		</div>

		<div class="bonus-item__col-right">
			<div class="bonus-item__side">
				<BonusReportFilters
					v-model="filters"
					:bonus-type="bonusType"
					:disabled="itemsLoadingStatus.loading"
					allowAllDatePeriod
					allowYTDDatePeriod
					class="bonus-item__filter-selector bonus-item__time-selector"
				/>

                <v-select
					v-model="carrier"
					label="Carrier"
					:items="availableCarriers"
					:disabled="itemsLoadingStatus.loading"
					color="#1383EA"
					class="bonus-item__filter-selector mt-8"
				/>

				<QAgentLookup
					v-if="isAgentLookupAvailable"
					v-model="lookupAgent"
					:filters="{ Status : 'Active,Available,Stalled' }"
					return-object
					:disabled="itemsLoadingStatus.loading"
					placeholder="Type to Search for Agent"
					hide-details
					color="#1383EA"
					class="bonus-item__filter-selector mt-2"
				/>

				<div class="bonus-item__actions">
					<v-btn
						v-if="!hasAdminPermissions"
						class="bonus-item__dispute-btn"
						text elevation="0" color="#1383EA"
						@click="$emit('submit-dispute', bonusType)"
					>
						Submit a Dispute Form
					</v-btn>
	
					<v-btn
						class="bonus-item__bird-dog-referrals-btn"
						outlined elevation="0" color="#1587E4"
						to="/bird-dog-dashboard"
						target="_blank"
					>
						Bird-Dog Referrals
						<v-icon right>fas fa-arrow-right</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { debounce } from 'vue-debounce'

import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import BonusReportFilters from './BonusReportFilters.vue'
import BirdDogBonusReportTable from './BirdDogBonusReportTable.vue'
import BonusItemMixin from './BonusItemMixin'
import { loadBonusReportType } from '../api/index'

const ALL = '-All-'

export default {
    components: {
		BonusReportFilters,
		BirdDogBonusReportTable,
		QAgentLookup,
	},
	mixins: [BonusItemMixin],
    data () {
        return {
			carrier: ALL,
			serverCarriers: [],

			lookupAgent: undefined,
        }
    },
    computed: {
		agentCode () {
			if (this.lookupAgent) {
				return this.lookupAgent.AgentCode
			}
			return this.agent ? this.agent.AgentCode : this.user.Agent.AgentCode
		},
		hasAdminPermissions () {
			return this.hasRole(['SuperAdmin', 'Staff'])
		},
		isAgentLookupAvailable () {
			if (this.hasAdminPermissions) { return true }
			return this.user.Agent.HasManagerAccess // agent has a downline
		},
		availableCarriers () {
			const carriers = this.serverCarriers.map((item) => item.CarrierName)
			return [ALL, ...carriers]
		},
		carrierID () {
			const carrier = this.serverCarriers.find(item => item.CarrierName === this.carrier)
			return carrier ? carrier.ID : null
		},
		exportResultsParams () {
			return {
				startDate: this.computedFilters.startDate,
				endDate: this.computedFilters.endDate,
				statType: this.computedFilters.statType,
				CarrierID: this.carrierID,
				...this.options,
				search: this.search,
				sortBy: this.options.sortBy.join(','),
				sortDesc: this.options.sortDesc.join(','),
				itemsPerPage: 'csv',
			}
		},
    },
	watch: {
		carrierID () {
			this.options.page = 1
			this.loadData()
		},
	},
	methods: {
        loadData: debounce(async function () {
			const setCarriersFlag = this.carrierID === null
			const filters = {
				startDate: this.computedFilters.startDate,
				endDate: this.computedFilters.endDate,
				statType: this.computedFilters.statType,
			}
			const params = {
				...this.options,
				...filters,
				search: this.search,
				...(setCarriersFlag && { needAvailableFilters: true }),
				CarrierID: this.carrierID,
			}
			this.setItemsLoadingStatus(true, false)
			const res = await loadBonusReportType(this.bonusType, this.agentCode, params)
			if (res.error) {
				this.totalBonus = 0
				this.items = []
				this.setItemsLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading ${this.title} Bonus data.<br>`, res.message)
			} else {
				this.totalBonus = res.bonusAmountSum
				this.items = res.data.map(item => ({ ...item, _details: { loading: false, error: false, data: null } }))
				this.serverItemsLength = res.meta?.total
				if (setCarriersFlag) {
					this.serverCarriers = res.filters?.Carrier || []
				}
				this.setItemsLoadingStatus(false, false)
			}
        }, 500),
		resetState () {
			this.filters = { ...this.defaultFilters }
			this.options.page = 1
			this.search = ''
			this.serverItemsLength = undefined
			this.carrier = ALL
			this.items = []
			this.totalBonus = 0
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.bonus-item {
	&__actions {
		margin-top: 3rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 3rem 3rem;
	}

	&__bird-dog-referrals-btn {
		text-transform: none;
		border-radius: $border-radius-xs;

		&:hover {
			text-decoration: none !important;
		}
	}
}
</style>
